import { action, thunk, useStoreActions, useStoreState } from "easy-peasy";

import { getFeatures, getFile } from "services/PlatformService";

import camelCase from "lodash/camelCase";
import forEach from "lodash/forEach";
import assign from "lodash/assign";

const features = {};

export const AppState = {
  features,
  isReady: false,
  performInit: thunk(async (actions, payload, helpers) => {
    window.AUTH_CONNECT_URL = process.env.REACT_APP_AUTH_CONNECT_URL;
    window.AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
    window.API_URL = process.env.REACT_APP_API_URL;
    const configJSON = await getFile("/config.json");
    forEach(configJSON, (value, name) => {
      window[name] = value;
    });
    actions.setAppData({
      ...configJSON,
    });
  }),
  setAppData: action((state, userData) => {
    assign(state, userData);
  }),
  setFeatures: action((state, featuresList = []) => {
    const featuresSet = featuresList.reduce(
      (acc, feature) => {
        acc[camelCase(feature.name)] = feature.isActive;
        return acc;
      },
      { ...features }
    );
    state.features = featuresSet;
  }),
};

export default AppState;

export { useStoreState };
export { useStoreActions };
