import "./App.css";
import "./i18n";

import React, { Suspense, lazy } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import AppTheme from "views/AppTheme";
import { LoadingIndicatorScreen } from "components/viewhelpers";
import { StateServiceProvider } from "whiteboard/StateService";
import ThemeType from "components/ThemeType";
import { useStoreState } from "whiteboard/AppState";
import PageNotFound from "views/PageNotFound";

const lazyWithMinWait = (importPromise, wait = 1000) => {
  return lazy(() => {
    return Promise.all([
      importPromise,
      new Promise((resolve) => setTimeout(resolve, wait)),
    ]).then(([moduleExports]) => moduleExports);
  });
};

const OnBoardingPortal = lazyWithMinWait(
  import("./views/Portals/OnBoardingPortal")
);

const LoadingScreen = () => {
  return <LoadingIndicatorScreen />;
};

const App = () => {
  return (
    <StateServiceProvider>
      <ThemeType>
        {(mode) => (
          <AppTheme themeType={mode}>
            <WaitForInit>
              <Router>
                <Suspense fallback={LoadingScreen()}>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/onboarding" />
                    </Route>
                    <Route path="/onboarding">
                      <OnBoardingPortal />
                    </Route>
                    <Route exact path={"/404"} component={PageNotFound} />
                  </Switch>
                </Suspense>
              </Router>
            </WaitForInit>
          </AppTheme>
        )}
      </ThemeType>
    </StateServiceProvider>
  );
};

export default App;

// be sure config is loaded before any of the app
function WaitForInit({ children }) {
  const isInit = useStoreState((store) => store.isInit);
  if (isInit) {
    return children;
  }
  return null;
}
