import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import "../App.css";
import makeTheme from "../theme";
import styled, { ThemeProvider } from "styled-components";

const AppTheme = ({ children, themeType = "light" }) => {
  const theme = makeTheme(themeType);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default AppTheme;
