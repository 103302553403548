import * as colors from "components/Colors";

import { createTheme } from "@material-ui/core/styles";

export default (type = "light") => {
  const m = (lightCol, darkCol) => {
    return type === "light" ? lightCol : darkCol;
  };
  const col = (colText) => {
    return type === "light"
      ? colors[`${colText}Light`]
      : colors[`${colText}Dark`];
  };
  const ocol = (colText) => {
    return type !== "light"
      ? colors[`${colText}Light`]
      : colors[`${colText}Dark`];
  };
  return createTheme({
    colors,
    m,
    col,
    ocol,
    palette: {
      type,
      background: {
        default: m("#F0EFF4", "#000000"),
      },
      primary: {
        main: m(colors.PrimaryLightColor, colors.PrimaryDarkColor),
        contrastText: m("#303030", "#FFFFFF"),
      },
      secondary: {
        main: m("#F0EFF4", "#FFFFFF"),
        contrastText: m("#303030", "#FFFFFF"),
      },
    },
    typography: { useNextVariants: true },
    props: {
      // Name of the component ⚛️
      MuiCheckbox: {
        // The default props to change
        color: "primary",
      },
      MuiSwitch: {
        color: "primary",
      },
    },
    overrides: {
      // Style sheet name ⚛️
      MuiInputBase: {
        // Name of the rule
        root: {
          // Some CSS
          backgroundColor: m("#FFFFFF", "#424242"),
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: m("#FFFFFF", "#424242"),
          "&&$focused": {
            backgroundColor: m("#FFFFFF", "#424242"),
          },
        },
      },
      MuiButton: {
        outlined: {
          backgroundColor: m("#FFFFFF", "#424242"),
        },
      },
      MuiListSubheader: {
        sticky: {
          backgroundColor: m("#F0EFF4", "#000000"),
          zIndex: 10,
          marginBottom: 6,
        },
      },

      MuiAppBar: {
        colorSecondary: {
          backgroundColor: m("#F0EFF4", "#000000"),
        },
        colorPrimary: {
          backgroundColor: m("#F0EFF4", "#000000"),
        },
      },
    },
  });
};
