import React from "react";
import AndorLogo from "images/andor-microsoft.svg";

const PageNotFound = () => {
  const { host, protocol } = window.location;
  return (
    <div className="w-full h-full flex-col justify-start items-center">
      <div className="w-full p-4 flex justify-center items-center">
        <img alt="andor logo" src={AndorLogo} style={{ height: "21px" }} />
      </div>
      <div className="flex-grow w-full mt-20 flex flex-col justify-center items-center">
        <div className="text-grayish-strong text-center p-4 mb-4">
          <h1 className="font-bold" style={{ fontSize: "9rem" }}>
            404
          </h1>
          <h3 className="font-semibold text-on-card text-lg">
            The page you are looking for does not exist.
          </h3>
        </div>
        <a
          href={protocol + "//" + host}
          className="border-none bg-brand text-on-brand px-6 py-3 font-medium rounded-full"
        >
          BACK TO HOMEPAGE
        </a>
      </div>
    </div>
  );
};
export default PageNotFound;
