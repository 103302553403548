import axios from "axios";
import get from "lodash/get";
import isString from "lodash/isString";
import queryString from "query-string";
import take from "lodash/take";

const fetchOptions = {
  withCredentials: true,
  credentials: "include",
};

const getAsJSON = (res) => {
  return res.json();
};

const performLoginWithAuthService = () => {
  const authorizationUrl = `${window.AUTH_CONNECT_URL}/authorize`;
  const clientid = window.AUTH_CLIENT_ID;
  const redirecturi = window.location.href;
  const responsetype = "id_token token";
  const scope = "openid uirestapi";
  const nonce = "N" + Math.random() + "" + Date.now();
  const state = Date.now() + "" + Math.random();

  const url =
    authorizationUrl +
    "?" +
    "response_type=" +
    encodeURI(responsetype) +
    "&" +
    "client_id=" +
    encodeURI(clientid) +
    "&" +
    "redirect_uri=" +
    encodeURI(redirecturi) +
    "&" +
    "scope=" +
    encodeURI(scope) +
    "&" +
    "nonce=" +
    encodeURI(nonce) +
    "&" +
    "state=" +
    encodeURI(state);
  console.log("LOGIN REDIRECT", url);
  window.location.href = url;
};

const performLogoutWithAuthService = ({ id_token }) => {
  const authorizationUrl = `${window.AUTH_CONNECT_URL}/endsession`;
  const id_token_hint = id_token;
  const post_logout_redirect_uri = window.location.origin + "/";

  const url =
    authorizationUrl +
    "?" +
    "id_token_hint=" +
    encodeURI(id_token_hint) +
    "&" +
    "post_logout_redirect_uri=" +
    encodeURI(post_logout_redirect_uri);
  console.log("LOGOUT REDIRECT", url);
  window.location.href = url;
};

const handleStatus = (e) => {
  const response = e.response ? e.response : e;
  const status = response ? response.status : "unknown";
  switch (status) {
    case 400:
    case 404:
    case 429:
    case 500:
    case "unknown":
      return console.error(e);
    case 401:
    case 403:
      // not authorized
      performLoginWithAuthService();
      break;
    default:
      // window.location.replace(`/problem?code=${status}`);
      break;
  }
  return e;
};

const logError = (e) => {
  handleStatus(e);
  return get(e, "response.data", {});
};

const requestService = (config) => {
  const { userKey, url, ...otherConfig } = config;
  const domain = url.indexOf("http") >= 0 ? "" : window.API_URL || "";
  const headers = window.userKey && {
    authorization: `Bearer ${window.userKey}`,
  };
  return axios
    .request({
      headers,
      url: `${domain}${url}`,
      ...otherConfig,
    })
    .then((res) => {
      if (res.status !== 200) {
        console.error("PLATFORM SERVICE ERROR", res);
      }
      return res;
    })
    .then((res) => res.data)
    .catch(logError);
};

const getService = (url, params, userKey) => {
  return requestService({
    method: "get",
    url,
    params,
    userKey,
  });
};

const postService = (url, data, userKey, other) => {
  return requestService({
    method: "post",
    url,
    data,
    userKey,
    ...other,
  });
};

const putService = (url, data, userKey, other) => {
  return requestService({
    method: "put",
    url,
    data,
    userKey,
    ...other,
  });
};

const deleteService = (url, params, userKey) => {
  return requestService({
    method: "delete",
    url,
    params,
    userKey,
  });
};

////////////

export const getFile = (filePath) => {
  return axios
    .get(filePath)
    .then((res) => {
      return res.data;
    })
    .catch(logError);
};

export const logoutUser = ({}, { id_token }) => {
  return new Promise((resolve, reject) => {
    performLogoutWithAuthService({ id_token });
  });
};

export const getFeatures = () => {
  return getService(`/api/Feature/GetFeatures`, {}).then((res) => res);
};

export const getQuestionnaire = ({
  tenantKey,
  questionnaireType,
  uriCode,
  personType,
}) => {
  const payload = uriCode
    ? {
        uriCode,
        personType,
      }
    : {
        tenantKey,
        questionnaireType,
        personType,
      };
  return getService(`/api/Questionnaire/GetQuestionnaire`, payload).then(
    (res) => res
  );
};

export const getPersonTypes = ({ tenantKey, questionnaireType }) => {
  return getService(
    `/api/Questionnaire/GetPersonTypes?tenantKey=${tenantKey}&questionnaireType=${questionnaireType}`
  );
};

export const validateEmployee = (payload) => {
  return getService(`/api/Questionnaire/ValidateEmployee`, payload);
};

export const getPersonTypeProperties = ({ tenantKey, personType }) => {
  return getService(
    `/api/Questionnaire/GetPersonTypeProperties?tenantKey=${tenantKey}&personType=${personType}`
  );
};
export const completeQuestionnaire = (data) => {
  return postService(`/api/Questionnaire/CompleteQuestionnaire`, data).then(
    (res) => res
  );
};

export const getSearchSuggestions = ({ term }) => {
  const data = { searchTerm: term, searchLimit: 10 };
  return postService(`/api/Education/GetProblemList`, data).then((res) => res);
};

export const getSearchResults = ({ term }) => {
  const data = { searchTerm: term };
  return postService(`/api/Education/GetEducationContentByTerm`, data).then(
    (res) => res
  );
};

export const GetTimeZones = (payload) => {
  return getService(`/api/Dictionary/GetTimeZones`, payload);
};

export const saveRegForm = (payload) => {
  return postService(`/api/onandor/create-account-organization`, payload);
};
export const saveBAAForm = (payload) => {
  return postService(`/api/onandor/create-account-baa`, payload);
};

export const validateDomain = (payload) => {
  return postService(`/api/TenantOnboarding/CheckDomain`, payload);
};

export const getFormData = (payload) => {
  return getService(`/api/form`, payload);
};

export const submitFormData = (payload) => {
  return postService(`/api/form`, payload);
};

export const getBranding = (payload) => {
  if (!payload.tenantKey) return {};
  return getService(`/api/onandor/branding`, payload);
};

export const getFormView = (payload) => {
  if (!payload.tenantKey && !payload?.name) return {};
  return getService(`/api/onandor/view-config`, payload);
};
