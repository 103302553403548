import React, { useEffect } from "react";
import { StoreProvider, action, createStore, thunk } from "easy-peasy";

import AppState from "whiteboard/AppState";
import OnBoardingState from "whiteboard/OnBoardingState";

export const store = createStore({
  app: AppState,
  onBoarding: OnBoardingState,
  isReady: false,
  isInit: false,
  performInit: thunk(async (actions, payload) => {
    await store.dispatch.app.performInit(store);
    actions.setAppInit();
  }),
  setAppInit: action((state) => {
    state.isInit = true;
  }),
  setAppReady: action((state) => {
    state.isReady = true;
  }),
});

export const StateServiceProvider = ({ children }) => {
  useEffect(() => {
    store.dispatch.performInit();
  });
  return <StoreProvider store={store}>{children}</StoreProvider>;
};
