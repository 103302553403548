import React from "react";
import { useStoreState } from "whiteboard/AppState";

const isNativeDarkMode = () => {
  return (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );
};

const ThemeType = ({ children }) => {
  // const isDarkModeActive = useStoreState(
  //   state => state.user.settings.isDarkModeActive
  // );
  const mode = "light";
  return <React.Fragment>{children(mode)}</React.Fragment>;
};

export default ThemeType;
