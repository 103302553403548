import {
  GetTimeZones,
  getBranding,
  getFormView,
  saveBAAForm,
  saveRegForm,
  saveTenantOnboarding,
  validateDomain,
} from "services/PlatformService";
import { action, thunk, useStoreActions, useStoreState } from "easy-peasy";

import assign from "lodash/assign";
import reject from "lodash/reject";

export const OnBoardingState = {
  isReady: false,
  organization: {},
  users: [],
  locations: [],
  payment: [],
  preferences: [],
  timezones: [],
  setupOnboarding: thunk(async (actions, payload, helpers) => {
    const timezones = await GetTimeZones();
    actions.setData({ timezones });
  }),
  setData: action((state, data) => {
    assign(state, data);
  }),
  addItem: action((state, { item, category }) => {
    const section = state[category];
    return _addItem(section, item);
  }),
  removeItem: action((state, { category, index }) => {
    const section = state[category];
    return _removeItemAt(section, index);
  }),
  saveOrganizationRegForm: thunk(async (actions, payload, helpers) => {
    const response = await saveRegForm({
      ...payload,
    });
    return response;
  }),
  saveBAAForm: thunk(async (actions, payload, helpers) => {
    const response = await saveBAAForm({
      ...payload,
    });
    if (response.isSuccess) {
      actions.setData({ sasUri: response?.sasUri });
    }
    return response;
  }),
  onValidateDomain: thunk(async (actions, { domain }, helpers) => {
    const response = await validateDomain({
      domain,
    });
    return response.data;
  }),
  getTenantLogo: thunk(async (actions, { tenantKey }, helpers) => {
    const response = await getBranding({
      tenantKey: tenantKey,
      name: "TENANT_GLOBALS",
    });
    return response;
  }),
  getFormView: thunk(async (actions, { tenantKey, name }, helpers) => {
    actions.setData({ isFormViewLoading: true });
    const response = await getFormView({
      tenantKey,
      name,
    });
    actions.setData({ isFormViewLoading: false });
    return response;
  }),
};

export default OnBoardingState;

export { useStoreState };
export { useStoreActions };

function _addItem(currentList, addItem) {
  const indexFound = currentList.findIndex((x) => x.id === addItem.id);
  if (indexFound === -1) {
    currentList.push(addItem);
  } else {
    currentList[indexFound] = addItem;
  }
  return currentList;
}

function _removeItemAt(currentList, index) {
  currentList.splice(index, 1);
  return currentList;
}
